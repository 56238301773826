import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Heading from '../components/Heading'
import Img from 'gatsby-image'

export const AboutPageTemaplte = ({ content, contentComponent, topPageItems }) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <PostContent content={content} />
          </div>
        </div>
      </div>
    </section>
  )
}

AboutPageTemaplte.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data, location }) => {
  const { markdownRemark: post } = data
  const mode = 'simple'

  const { edges: posts } = data.allMarkdownRemark
  const list = posts ? posts : []
  const topImageItems = list.filter(({ node: post }) => {
    const frontmatter = post.frontmatter
    return frontmatter.topimage ? true : false
  })


  const HeaderBackLink =
    mode === 'simple'
      ? () => (
          <Link to="/">
            <div className="header__back--simple">Gosuke Kambayashi / plat</div>
          </Link>
        )
      : () => ''

  const imageStyle = {}
  const className = ''
  if (mode === 'simple') {
    return (
      <Layout mode={mode}>
        <Helmet titleTemplate="About | %s" />
        <HeaderBackLink />
        <section className="section">
      
          <div className="container content">
            <div className="columns">
              <div className="column is-10 is-offset-1">

                <div>
                  <a className="black-link" href="#works">
                    <span style={{fontSize:'0.5em', position: 'relative', top: '-0.3em'}}>▶</span>
                    <span>Works</span>
                  </a>
                </div>
                <div style={{paddingBottom: '2em'}}>
                  <a className="black-link" href="#about">
                    <span style={{fontSize:'0.5em', position: 'relative', top: '-0.3em'}}>▶</span>
                    <span>About</span>
                  </a>
                </div>

                <div id="works">-Works-</div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {
                    topImageItems.map(({ node: post }) => (
                        <Link key={post.id} to={`${post.fields.slug}?from=top`} className="hover-opacity-04" style={{ flex: '0 0 15%', margin: '0.5%' }} >
                          <Img
                            style=''
                            fluid={post.frontmatter.topimage.childImageSharp.fluid}
                            alt=''
                            className=''
                          />
                        </Link>
                      ))
                  }
                </div>
              </div>
            </div>
          </div>  
        </section>
        <AboutPageTemaplte content={post.html} contentComponent={HTMLContent} />
      </Layout>
    )
  }

  return (
    <Layout mode={mode}>
      <Helmet titleTemplate="About | %s" />
      <Heading heading="About" />
      <AboutPageTemaplte content={post.html} contentComponent={HTMLContent} />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "works-page" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            topimage {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
